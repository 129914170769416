.projects {
	&__body {
		position: relative;
	}

	&__background-decor {
		top: var(--backgroundDecorWidth);
		right: calc(
			-1 * var(--paddingContainer) - var(--backgroundDecorWidth) * 2
		);
	}

	&__filter-country {
		margin-bottom: 64px;

		&.hide {
			display: none;
		}
	}

	&__category-empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 auto;

		img {
			width: 30%;
			height: auto;
		}

		p {
			font-weight: 600;
			font-size: clamp(16px, (32/1510) * 100vw, 32px);
			margin-top: clamp(24px, (42/1510) * 100vw, 42px);
			line-height: 145%;
			color: $darkBlue;
			text-align: center;
		}
	}

	@media (max-width: 900px) {
		&__category-empty {
			img {
				width: 50%;
				height: auto;
			}
		}
	}
}
