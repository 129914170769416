.background-decor {
	width: var(--backgroundDecorWidth);
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	gap: clamp(8.5px, (17/1510)*100vw, 17px);
	cursor: none;
	overflow: hidden;
	pointer-events: none;

	&__item {
		flex: 0 0 var(--backgroundDecorItemHeigth);
		height: var(--backgroundDecorItemHeigth);
		background: rgba(28, 38, 145, 0.1);
		border-radius: 50%;
	}
}
