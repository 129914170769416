.header-navigation {
	height: 100%;
	overflow: hidden;
	transition: $searchFocusAnimation;

	&__list {
		width: 100%;
		height: 100%;
		display: flex;
	}

	&__item {
		height: 100%;

		&:not(:last-child) {
			margin-right: clamp(24px, (48 /#{$desktop}) * 100vw, 48px);
		}
	}

	&__button {
		display: flex;
		align-items: center;
		position: relative;
		height: 100%;
		font-family: $fontPlayfair;
		font-weight: 400;
		font-size: clamp(12px, (16/1510) * 100vw, 16px);
		color: $black;
		white-space: nowrap;
		transition: all 0.3s ease;

		&::after {
			--heightLine: 3px;

			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: var(--heightLine);
			background-color: transparent;
			border-radius: 10px;
			transition: all 0.3s ease;
		}

		&:hover,
		&.active {
			color: $strokeCard;

			&::after {
				background-color: $strokeCard;
			}
		}
	}

	@media (max-width: 991px) {
		&__list {
			width: auto;
			height: auto;
			display: block;
			position: absolute;
			top: 50%;
			left: var(--paddingContainer);
			transform: translateY(-50%);
		}

		&__item {
			height: auto;

			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: clamp(24px, (48/991) * 100vw, 48px);
			}
		}

		&__button {
			font-size: 16px;

			&::after {
				--heightLine: 0px;
			}
		}
	}

	@media (max-width: 645px) {
		height: 305px;

		&__list {
			position: relative;
			top: calc(var(--headerHeight) + 20px);
			bottom: 24px;
			left: var(--paddingContainer);
			transform: translateY(0);
		}

		&__item {
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}

		&__button {
			font-size: 16px;
		}
	}

	@media (max-height: 815px) {
		&__item {
			&:not(:last-child) {
				margin-bottom: clamp(2px, (24/991) * 100vh, 24px);
			}
		}
	}
}
