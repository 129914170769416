.services-engineering {
	width: 100%;

	&__title {
		margin-bottom: 32px;
	}

	&__cards {
		display: flex;
		gap: 20px;
	}

	@media (max-width: 1199px) {
		&__cards {
			flex-wrap: wrap;
		}
	}
}
