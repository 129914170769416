// .about-file-item {
// 	--aboutFileIcon: clamp(60px, (80/768) * 100vw, 80px);
// 	position: relative;
// 	background: url(../img/icons/fileDownload.svg) no-repeat transparent top
// 		left / 64px var(--aboutFileIcon);
// 	padding-left: calc(var(--aboutFileIcon) + 15px);

// 	&__title {
// 		height: 100%;
// 		font-size: clamp(14px, (18/768) * 100vw, 18px);
// 		font-weight: 600;
// 		overflow: hidden;
// 		// width: 270px;
// 	}

// 	&__truncate {
// 		@include textTruncateVertical(2);
// 	}

// 	&__button {
// 		position: absolute;
// 		left: calc(var(--aboutFileIcon) + 15px);
// 		bottom: 0;
// 		font-size: 16px;
// 	}
// }

.about-file-item {
	--aboutFileIcon: clamp(60px, (80/768) * 100vw, 80px);
	position: relative;
	background: url(../img/icons/fileDownload.svg) no-repeat transparent top
		left / 64px var(--aboutFileIcon);
	padding-left: calc(var(--aboutFileIcon) + 15px);
	margin-bottom: 15px;

	&__title {
		height: 100%;
		font-size: clamp(14px, (18/768) * 100vw, 18px);
		font-weight: 600;
		overflow: hidden;
	}

	&__button {
		position: absolute;
		left: calc(var(--aboutFileIcon) + 15px);
		bottom: 10px;
		font-size: 16px;
	}
}
