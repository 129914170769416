.vacancies-inside {
	overflow: visible;

	&__body {
		display: flex;
		gap: 0 86px;
	}

	&__title {
		margin-bottom: 64px;
	}

	&__aside {
		position: sticky;
		flex: 0 0 453px;
		align-self: flex-start;
		top: calc(var(--headerHeight) + 20px);
		margin-left: auto;
	}

	&__aside-title {
		color: $blue;
		margin-bottom: 32px;
	}

	&__content {
		flex: 1 1 auto;
	}

	@media (max-width: 1201px) {
		&__aside {
			display: none;
		}
	}
}
