.spoiler {
	--contentRowHeight: 84px;
	--spoilerTitleArrowSize: 32px;
	overflow: hidden;

	&.open {
		.spoiler__content {
			max-height: 2000px;
			opacity: 1;
		}

		.spoiler__title {
			margin-bottom: clamp(16px, (48/1920) * 100vw, 48px);
			transition: margin-bottom 0.3s linear;
		}

		.spoiler__title-arrow {
			&::before {
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&::after {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}

	&__title {
		position: relative;
		max-width: calc(680px + (3 * var(--spoilerTitleArrowSize)));
		min-height: 80px;
		font-family: $fontPlayfair;
		font-weight: 400;
		font-size: clamp(16px, (24/1024) * 100vw, 24px);
		cursor: pointer;
		user-select: none;
		margin-bottom: 0;
		padding-right: calc(2.5 * var(--spoilerTitleArrowSize));
		transition: margin-bottom 0.3s linear 0.3s;
	}

	&__title-arrow {
		position: absolute;
		top: 2px;
		right: 5px;
		width: var(--spoilerTitleArrowSize);
		height: var(--spoilerTitleArrowSize);
		z-index: -1;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 0.5px;
			background-color: $black;
			transition: all 0.3s ease;
		}

		&::before {
			left: 37.5%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			left: 62.5%;
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__content {
		width: 100%;
		max-height: 0;
		padding: 0;
		transition: all 0.5s linear;
		opacity: 0;
	}

	&__content-row {
		width: 100%;
		min-height: var(--contentRowHeight);
		display: flex;
		align-items: center;
		gap: 0 clamp(24px, (50/1440) * 100vw, 50px);
		padding: clamp(18px, (32/1510) * 100vw, 32px) 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&:not(:last-child) {
			border-bottom: 1px solid $greyBorder;
		}
	}

	&__content-col {
		height: 100%;

		&--title {
			flex: 0 1 20%;
		}

		&--description {
			flex: 0 1 60%;
		}

		&--button {
			flex: 0 1 20%;
			text-align: right;
		}
	}

	&__content-title {
		font-size: clamp(16px, (18/1440) * 100vw, 18px);
		font-weight: 600;
	}

	&__content-description {
		font-size: clamp(14px, (18/1440) * 100vw, 18px);
		overflow: hidden;
		@include textTruncateVertical(3);

		p:first-child {
			@include textTruncateVertical(3);
		}

		p:not(:first-child),
		ul,
		img,
		iframe {
			display: none;
		}
	}

	&__content-button {
		font-size: 16px;
	}

	@media (max-width: 1199px) {
		&__content-row {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__content-col {
			&--title {
				flex: 1 1 auto;
				margin-bottom: clamp(16px, (32/1199) * 100vw, 32px);
			}

			&--description {
				flex: 1 1 auto;
				margin-bottom: 24px;
			}
		}
	}

	@media (max-width: 889px) {
		&__title {
			max-width: 100%;
			padding-right: 50px;
		}

		&__title-arrow {
			top: 0;
			right: 0;
		}
	}
}
