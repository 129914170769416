.burger-button {
	--buttonWidth: clamp(26px, (32/991)*100vw, 32px);
	--buttonHeigth: clamp(14px, (18/991)*100vw, 18px);
	--heightLine: 1px;

	position: relative;
	width: var(--buttonWidth);
	height: var(--buttonHeigth);
	background-color: transparent;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span {
		position: absolute;
		width: 100%;
		left: 0;
		height: var(--heightLine);
		border-radius: 6px;
		background-color: $black;
		transition: all 0.15s ease;
	}

	span:first-child {
		top: 0;
	}

	span:last-child {
		top: calc(var(--buttonHeigth) - var(--heightLine));

	}

	&.active {
		span {
			top: 50%;
			opacity: 0;
			transform: translateY(-50%) rotate(0);
		}

		span:first-child {
			opacity: 1;
			transform: translateY(-50%) rotate(45deg);
		}

		span:last-child {
			opacity: 1;
			transform: translateY(-50%) rotate(-45deg);
		}
	}
}
