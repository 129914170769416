@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src: local('Montserrat'),
		url('../fonts/montserrat-light.woff2') format('woff2'),
		url('../fonts/montserrat-regular.woff') format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local('Montserrat'),
		url('../fonts/montserrat-regular.woff2') format('woff2'),
		url('../fonts/montserrat-regular.woff') format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src: local('Montserrat'),
		url('../fonts/montserrat-semibold.woff2') format('woff2'),
		url('../fonts/montserrat-semibold.woff') format('woff');
}

@font-face {
	font-family: 'Playfair Display SC';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local('Playfair Display SC'), local('Playfair-Display-SC'),
		url('../fonts/playfairdisplaysc-regular.woff2') format('woff2'),
		url('../fonts/playfairdisplaysc-regular.woff') format('woff');
}

@font-face {
	font-family: 'Playfair Display SC';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: local('Playfair Display SC'), local('Playfair-Display-SC'),
		url('../fonts/playfairdisplaysc-bold.woff2') format('woff2'),
		url('../fonts/playfairdisplaysc-bold.woff') format('woff');
}

@font-face {
	font-family: 'Playfair Display';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: local('Playfair Display'), local('Playfair-Display'),
		url('../fonts/playfairdisplay-regular.woff2') format('woff2'),
		url('../fonts/playfairdisplay-regular.woff') format('woff');
}
