.footer {
	width: 100%;
	padding: var(--footerPaddingVertical) 0;
	background-color: $greyDark;

	&__social {
		--contactSocialSize: 46px;
		display: none;
	}

	&__body {
		display: flex;
		justify-content: space-between;
	}

	&__title {
		margin-bottom: clamp(24px, (32/768) * 100vw, 32px);
	}

	&__marketing,
	&__contact,
	&__project,
	&__about {
		flex: 0 1 24%;
	}

	@media (max-width: 1199px) {
		&__social {
			display: flex;
		}

		&__body {
			flex-wrap: wrap;
			justify-content: space-between;
			row-gap: clamp(32px, (64/1199) * 100vw, 64px);
		}

		&__project {
			padding-right: 0px;
		}

		&__project,
		&__contact {
			flex: 0 1 48%;
		}

		&__about,
		&__marketing {
			flex: 0 1 48%;
		}
	}

	@media (max-width: 680px) {
		&__social {
			justify-content: center;

			.contact-social__item {
				flex: 0 1 46px;
			}
		}

		&__project,
		&__contact,
		&__about,
		&__marketing {
			flex: 0 1 100%;
		}
	}
}
