.map {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $white;

	&__container {
		width: 100%;
		height: 100%;
	}

	&__placeholder-loading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(270deg, $white, $greyBorder);
		background-size: 400% 400%;
		animation: placeholderLoadingAnimation 2.5s ease-in-out infinite;
	}
}

@keyframes placeholderLoadingAnimation {
	0%,
	100% {
		background-position: 0% 50%
	}

	50% {
		background-position: 100% 50%
	}
}
