.header-language {

	&__list {
		display: flex;
	}

	&__item {
		display: flex;
		margin-right: 16px;

		&:last-child {
			margin-right: 32px;
		}
	}

	&__link {
		font-family: $fontPlayfair;
		font-weight: 400;
		font-size: clamp(12px, (16/1510)*100vw, 16px);
		color: $grey2;
		transition: color 0.3s ease;

		&:hover,
		&.active {
			color: $strokeCard;
		}
	}

	@media (max-width: 991px) {
		&__item {
			&:last-child {
				margin-right: 24px;
			}
		}
	}

	@media (max-width: 645px) {
		&__link {
			font-size: 16px;
		}
	}
}
