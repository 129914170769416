.slider {
	--buttonControlWidth: 29px;
	--sliderBulletSize: 10px;
	height: 100%;
	user-select: none;
	position: relative;

	&__swiper {
		display: flex;
		height: 100%;
		padding-bottom: 34px !important;
	}

	&__button-prev,
	&__button-next {
		position: absolute;
		height: var(--buttonControlHeight);
		width: var(--buttonControlWidth);
		top: 50%;
		background: url(../img/icons/arrow.svg) no-repeat $white center left / 12px 24px;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s linear;

		&--big {
			--buttonControlHeight: 207px;
		}

		&--middle {
			--buttonControlHeight: 100px;
		}
	}

	&__image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&__button-prev {
		left: 0;
		transform: translateY(-50%) rotate(0);
	}

	&__button-next {
		right: 0;
		transform: translateY(-50%) rotate(180deg);
	}

	&:hover .slider__button-prev,
	&:hover .slider__button-next {
		opacity: 1;
		visibility: visible;
	}

	&__pagination {
		bottom: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(var(--sliderBulletSize) * 3);
	}

	&__bullet {
		display: inline-block;
		width: var(--sliderBulletSize);
		height: var(--sliderBulletSize);
		border-radius: 50%;
		background: transparent;
		border: 1px solid $black;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 10px;
		}

		&--active {
			background: $black;
		}
	}

	@media (max-width: 991px) {
		&:hover .slider__button-prev,
		&:hover .slider__button-next {
			opacity: 0;
			visibility: hidden;
		}
	}
}
