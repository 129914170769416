@mixin textTruncateVertical($numberOfLines) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $numberOfLines;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}

@mixin animationLiftingInitial {
	box-shadow: $defaultShadow;
	transition:
		transform 0.4s ease,
		box-shadow 0.4s ease;
}

@mixin animationLiftingHover($scale, $shadow) {
	transform: scale($scale);
	box-shadow: $shadow;
}

@mixin defaultTextUnderline {
	text-decoration: underline;
	text-decoration-thickness: 0.5px;
	text-underline-offset: 3px;
}
