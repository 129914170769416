.main-about-slider {
	--sliderRightSpace: 72px;
	--sliderWidth: clamp(288px, (1045/1510)*100vw, 1045px);
	--sliderHeight: clamp(134px, (467/1510)*100vw, 467px);
	--bulletSize: 12px;
	--paginationSpace: 32px;
	--slidertranslateY: clamp(14px, (40/1510)*100vw, 40px);

	position: relative;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	transform: translateY(var(--slidertranslateY));
	z-index: $zIndexAboutSlider;
	user-select: none;

	&__background-decor {
		top: -14px;
		left: 0;
	}

	&__body {
		width: var(--sliderWidth);
		height: var(--sliderHeight);
		margin-right: var(--sliderRightSpace);
	}

	&__swiper {
		height: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__pagination {
		position: absolute;
		height: var(--bulletSize);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		bottom: var(--paginationSpace) !important;
		right: 0 !important;
	}

	&__bullet {
		display: inline-block;
		width: var(--bulletSize);
		height: var(--bulletSize);
		border-radius: 50%;
		background: transparent;
		border: 1px solid $white;
		margin-right: 10px;
		cursor: pointer;

		&:last-child {
			margin-right: var(--paginationSpace);
		}

		&--active {
			background: $white;
		}
	}

	@media (max-width: 1199px) {
		--sliderRightSpace: 52px;
		--slidertranslateY: 0;
		--paginationSpace: 16px;
		--bulletSize: 10px;

		&__background-decor {
			top: 0;
		}
	}

	@media (max-width: 1010px) {
		--sliderRightSpace: 32px;
	}

	@media (max-width: 645px) {
		--sliderWidth: 100%;
		--sliderHeight: clamp(134px, (250/645)*100vw, 250px);;
		--sliderRightSpace: 0;
	}
}
