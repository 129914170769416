.about-info-text {
	width: 100%;

	p:not(:last-child) {
		margin-bottom: 24px;
	}

	b {
		font-weight: 600;
	}
}
