.main-about-tabs {
	--tabsPaddingTop: clamp(20px, (116/1920) * 100vw, 116px);
	--tabsContentHeight: clamp(420px, (750/1510) * 100vw, 750px);
	--tabsContentTextHeight: clamp(260px, (315/1510) * 100vw, 315px);
	--tabsContentPaddingLeft: clamp(84px, (135/1510) * 100vw, 135px);
	--navButtonWidth: 210px;
	--navButtonHeight: 26px;
	position: relative;
	width: 100%;
	height: 100%;

	&__background-decor {
		&.top-left {
			--space: 45px;
			top: var(--space);
			left: calc(
				-1 * var(--paddingContainer) - var(--backgroundDecorWidth) - var(--space)
			);
		}

		&.bottom-right {
			--space: 83px;
			bottom: var(--space);
			right: calc(
				-1 * var(--paddingContainer) - var(--backgroundDecorWidth) - var(--space)
			);
		}
	}

	&__body {
		width: 100%;
		height: 100%;
		display: flex;
	}

	&__nav {
		flex: 1 1 25%;
		padding-top: var(--tabsPaddingTop);
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: var(--tabsContentHeight);
		border-right: 1px solid $greyBorder;
	}

	&__nav-button {
		--circleSize: clamp(14px, (18/1510) * 100vw, 18px);
		position: relative;
		width: 100%;
		min-height: var(--navButtonHeight);
		text-align: left;
		letter-spacing: 0.5px;
		z-index: 1;

		&:hover,
		&.active {
			&::before {
				background-color: $blue;
			}

			&::after {
				border: 1px solid $blue;
				background-color: $blue;
			}

			span {
				color: $blue;
			}
		}

		&:not(:last-child) {
			margin-bottom: 32px;
		}

		span {
			display: inline-block;
			font-family: $fontPlayfair;
			font-size: clamp(14px, (18/1510) * 100vw, 18px);
			color: $grey2;
			width: var(--navButtonWidth);
			transition: all 0.3s ease;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			transition: all 0.3s ease;
		}

		&::before {
			top: calc(var(--navButtonHeight) / 2);
			right: clamp(21px, (53/1510) * 100vw, 53px);
			width: clamp(21px, (53/1510) * 100vw, 53px);
			height: 1px;
			background-color: $grey2;
		}

		&::after {
			top: 4px;
			right: calc(var(--circleSize) / (-2));
			width: var(--circleSize);
			height: var(--circleSize);
			background-color: $white;
			border: 1px solid $grey2;
			border-radius: 50%;
		}
	}

	&__content {
		position: relative;
		flex: 1 1 75%;
		height: var(--tabsContentHeight);
	}

	&__content-item {
		position: absolute;
		top: var(--tabsPaddingTop);
		width: 100%;
		overflow: hidden;
		padding-left: var(--tabsContentPaddingLeft);
		visibility: hidden;
		opacity: 0;

		&.active {
			visibility: visible;
			opacity: 1;

			.main-about-tabs__content-text {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&__content-text {
		position: relative;
		width: 100%;
		min-height: var(--tabsContentTextHeight);
		font-size: clamp(14px, (24/1510) * 100vw, 24px);
		font-weight: 300;
		letter-spacing: -0.5px;
		overflow: hidden;
		margin-bottom: 30px;
		box-shadow: 0px -29px 8px -3px rgba(255, 255, 255, 0.9) inset;
		visibility: hidden;
		opacity: 0;
		transition: opacity 2s ease;

		p:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__content-button {
		margin-top: auto;
		font-size: clamp(16px, (21/1510) * 100vw, 21px);

		&.hide {
			display: none;
		}
	}

	@media (max-width: 1366px) {
		&__nav {
			flex: 1 1 30%;
		}

		&__content {
			position: relative;
			flex: 1 1 70%;
			height: var(--tabsContentHeight);
		}

		&__content-item {
			padding-left: 50px;
		}
	}

	@media (max-width: 991px) {
		&__nav-button {
			&::before {
				content: none;
			}
		}
	}

	@media (max-width: 699px) {
		display: none;
	}
}
