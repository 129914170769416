.main-management {
	position: relative;
	width: 100%;
	height: 100%;

	&__background-decor {
		&.center-left {
			top: 50%;
			left: 38px;
			transform: translateY(-50%);
		}

		&.top-right {
			top: 35%;
			right: calc(-2 * var(--backgroundDecorItemHeigth));
		}
	}
}
