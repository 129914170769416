.logo-dropdown {
	--logoSize: clamp(32px, (48/1510)*100vw, 48px);
	--listDropdownWidth: 346px;
	--listDropdownHeigth: 258px;
	--listItemDropdownHeight: 86px;

	position: relative;
	display: flex;
	justify-content: space-between;
	width: calc(var(--logoSize) * 2);
	height: var(--logoSize);

	&.open {
		.logo-dropdown__list {
			visibility: visible;
			display: flex;
			opacity: 1;
			transform: translateY(0);
		}

		.logo-dropdown__logo-arrow::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		.logo-dropdown__logo-arrow::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	&__logo-link {
		position: relative;
		flex: 0 0 var(--logoSize);
		height: 100%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: contain;
		}
	}

	&__logo-arrow {
		flex: 0 0 var(--logoSize);
		height: 100%;
		position: relative;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 0.5px;
			background-color: $black;
			transition: all 0.3s ease;
		}

		&::before {
			left: 12px;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			left: 20px;
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		position: absolute;
		left: 0;
		top: calc(var(--logoSize) + var(--headerPaddingTop) + 5px);
		width: var(--listDropdownWidth);
		height: var(--listDropdownHeigth);
		overflow: hidden;
		border-radius: 4px;
		background-color: $white;
		box-shadow: $defaultShadow;
		visibility: hidden;
		opacity: 0;
		transform: translateY(25px);
		transition: all 0.3s ease;
		z-index: $zIndexLogoDropdown;

	}

	&__item {
		flex: 1 1 33.3%;
		width: 100%;
		height: var(--listItemDropdownHeight);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.1s ease;

		&:hover {
			background-color: $grey;
		}
	}

	&__link {
		flex: 0 0 90%;
		height: 100%;
		display: flex;
		align-items: center;
	}

	&__item-logo {
		--itemLogoSize: clamp(31px, (40/1510)*100vw, 40px);
		position: relative;
		flex: 0 0 var(--itemLogoSize);
		height: var(--itemLogoSize);
		overflow: hidden;
		margin-right: 24px;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: contain;
		}
	}

	&__item-name {
		flex: 0 1 auto;
		font-size: 14px;
		font-weight: 400;
		color: $black;

		b {
			font-weight: 600;
		}
	}

	@media (max-width: 1099px) {
		&__list {
			top: calc(var(--headerHeight) - 10px);
		}
	}

	@media (max-width: 400px) {
		--listDropdownWidth: 295px;

		&__item-name {
			font-size: 12px;
		}
	}
}

