.projects-dropdown {
	--projectsDropdownArrowWidth: 22px;
	--projectsDropdownArrowHeight: 2.5px;
	position: relative;
	display: inline-block;

	&.open {
		.projects-dropdown__list {
			visibility: visible;
			display: block;
			opacity: 1;
			transform: translateY(0);
		}

		.projects-dropdown__title::before {
			transform: rotate(-47deg);
		}

		.projects-dropdown__title::after {
			transform: rotate(47deg);
		}
	}

	&__title {
		position: relative;
		padding-right: calc(var(--projectsDropdownArrowWidth) + 30px);
		cursor: pointer;
		user-select: none;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 52%;
			width: var(--projectsDropdownArrowWidth);
			height: var(--projectsDropdownArrowHeight);
			background-color: $black;
			transition: all 0.3s ease;
		}

		&::before {
			right: 14px;
			transform: rotate(47deg);
		}

		&::after {
			right: 0px;
			transform: rotate(-47deg);
		}
	}

	&__list {
		position: absolute;
		top: clamp(50px, (92/1510) * 100vw, 92px);
		right: 0;
		visibility: hidden;
		overflow: auto;
		max-height: 57vh;
		opacity: 0;
		transform: translateY(25px);
		transition: opacity 0.3s ease, transform 0.3s ease;
		z-index: $zIndexProjectsDropdown;

		&--left-side {
			left: 0;
			min-width: 400px;
		}

		&::-webkit-scrollbar {
			width: 3px;
			height: 0;
		}

		&::-webkit-scrollbar-track {
			background-color: #e4e4e4;
			border-radius: 100px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $blue;
			box-shadow: inset 2px 2px 3px 0 rgba($blue, 1);
		}

		@media (max-width: 499px) {
			min-width: 300px !important;
		}
	}

	@media (max-width: 991px) {
		--projectsDropdownArrowWidth: 15px;
		--projectsDropdownArrowHeight: 1.5px;

		&__title {
			&::before {
				right: 9px;
			}
		}
	}

	@media (max-width: 645px) {
		--projectsDropdownArrowWidth: 10px;
		--projectsDropdownArrowHeight: 1px;

		&__title {
			font-size: 18px;
			&::before {
				right: 7px;
			}
		}
	}
}
