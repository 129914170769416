.services-card {
	--servicesCardIconSize: clamp(40px, (64/1155)*100vw, 64px);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	text-align: center;
	padding-top: clamp(24px, (64/1510)*100vw, 64px);
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	background-color: $white;
	font-size: clamp(14px, (18/768)*100vw, 18px);
	flex: 0 1 var(--servicesCardWidth);
	max-width: var(--servicesCardWidth);
	min-height: var(--servicesCardHeight);
	@include animationLiftingInitial;

	&__body {
		max-width: var(--servicesCardBodyWidth);
	}

	&--big {
		--servicesCardWidth: 690px;
		--servicesCardHeight: 352px;
		--servicesCardBodyWidth: 555px;

		.services-card__icon {
			margin-bottom: 24px;
		}
	}

	&--middle {
		--servicesCardWidth: 335px;
		--servicesCardHeight: clamp(192px, (352/768)*100vw, 352px);
		--servicesCardBodyWidth: 271px;

		.services-card__icon {
			margin-bottom: clamp(24px, (43/1155)*100vw, 43px);
		}
	}

	&__icon {
		width: var(--servicesCardIconSize);
		height: var(--servicesCardIconSize);
		fill: $black;
		transition: all 0.4s ease;
	}

	&__text {
		font-size: clamp(14px, (18/768), 18px);
		cursor: default;
		transition: all 0.4s ease;
	}

	&:hover {
		@include animationLiftingHover(1.025, $defaultShadowHover);

		.services-card__icon {
			fill: $blue;
		}

		.services-card__text {
			color: $blue;
		}
	}

	@media (max-width: 1249px) {
		padding: clamp(24px, (64/1155)*100vw, 64px) 15px;
		&--big {
			--servicesCardWidth: 100%;
			--servicesCardHeight: auto;
		}
	}

	@media (max-width: 1199px) {
		&--middle {
			--servicesCardWidth: 48%;
			--servicesCardHeight: auto;
		}
	}

	@media (max-width: 991px) {
		&:hover {
			@include animationLiftingHover(1, $defaultShadow);

			.services-card__icon {
				fill: $black;
			}

			.services-card__text {
				color: $black;
			}
		}
	}

	@media (max-width: 555px) {
		&--middle {
			--servicesCardWidth: 100%;
			--servicesCardBodyWidth: 90%;
		}
	}
}
