.main-about-spoilers {
	--spoilerTitleArrowSize: 24px;
	display: none;
	padding: 24px 0;

	&__item {
		overflow: hidden;
		margin-bottom: 24px;

		&.open {
			.main-about-spoilers__content {
				max-height: 800px;
				opacity: 1;
			}

			.main-about-spoilers__title {
				color: $blue;
				margin-bottom: 16px;
			}

			.main-about-spoilers__title-arrow {
				&::before {
					transform: translate(-50%, -50%) rotate(-45deg);
					background-color: $blue;
				}

				&::after {
					transform: translate(-50%, -50%) rotate(45deg);
					background-color: $blue;
				}
			}
		}
	}

	&__title {
		position: relative;
		font-family: $fontPlayfair;
		font-size: 18px;
		font-weight: 400;
		color: $grey2;
		font-size: 18px;
		user-select: none;
		padding-right: var(--spoilerTitleArrowSize);
		transition: all 0.3s linear;
	}

	&__title-arrow {
		position: absolute;
		top: 2px;
		right: 0;
		width: var(--spoilerTitleArrowSize);
		height: var(--spoilerTitleArrowSize);

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 0.5px;
			background-color: $black;
			transition: all 0.3s linear;
		}

		&::before {
			left: 34%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			left: 66%;
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__content {
		width: 100%;
		max-height: 0;
		padding-top: 0;
		opacity: 0;
		transition: all 0.9s ease;

		p {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 16px;
		}
	}

	&__content-wrapper {
		width: 100%;
	}

	&__content-description {
		font-size: 18px;
	}

	&__content-button {
		font-size: 16px;
		margin-bottom: 16px;

		&.hide {
			display: none;
		}
	}

	@media (max-width: 699px) {
		display: block;
	}
}
