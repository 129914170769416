.map-svg {

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: $grey2;
		stroke-width: 0.4px;
		stroke: $white;
		cursor: default;
	}

	.ET,
	.UA,
	.RU,
	.GE,
	.BY,
	.AF,
	.VN {
		fill: $white;
		transition: fill 0.3s ease;
		cursor: pointer;

		&:hover {
			fill: $orange;
		}
	}

	@media (max-width: 991px) {
		.ET,
		.UA,
		.RU,
		.GE,
		.BY,
		.AF,
		.VN {
			fill: $orange;
		}
	}
}
