.main-projects {
	--mainProjectsHeight: clamp(488px, (891/1510)*100vw, 891px);
	--mainProjectsPaddingVertical: 80px;
	--backgroundDecorWidth: clamp(108.5px, (217/1510)*100vw, 217px);
	--backgroundDecorHeight: clamp(45.5px, (91/1510)*100vw, 91px);
	--buttonControlSize: clamp(45.5px, (91/1510)*100vw, 91px);
	--slideWidth: 453px;
	--slideHeight: 295px;
	--slideWidthActive: 620px;
	--slideHeightActive: 403px;
	--sliderWidth: 1574px;

	position: relative;
	width: 100%;
	height: var(--mainProjectsHeight);
	background-color: rgba(0, 0, 0, 0.7);
	padding: var(--mainProjectsPaddingVertical) 0;
	user-select: none;

	&__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__header {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
	}

	&__title {
		color: $white;
	}

	&__button {
		font-size: clamp(16px, (21/1510)*100vw, 21px);
		align-self: flex-end;
		margin-left: auto;
		margin-bottom: clamp(7.5px, (15/1920)*100vw, 15px);

		&--mobile {
			display: none;
		}
	}

	&__background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
		z-index: -1;
		transition: opacity 1.1s ease-out;

		&.active {
			opacity: 1;
		}
	}

	&__body {
		flex: 1 1 auto;
		width: var(--sliderWidth);
		display: flex;
		align-items: center;
		transform: translateX(calc(-1 * var(--paddingContainer)));
	}

	&__slider {
		width: 100%;
		height: var(--slideHeight) !important;
	}

	.swiper-wrapper {
		width: 100%;
		align-items: flex-start;
	}

	&__slide {
		position: relative;
		margin: 0;
		padding: clamp(16px, (32/1440)*100vw, 32px) 32px;
		height: var(--slideHeight) !important;
		border: 1px solid $white;
		background: transparent !important;
		transition: height 1.1s linear;
		overflow: hidden;

		&.swiper-slide-active {
			border: 1px solid transparent;
			height: var(--slideHeightActive) !important;
			transition: height 1.1s linear;

			.main-projects__slide-image {
				opacity: 1;
				transition: opacity 1.1s linear;
			}

			.main-projects__slide-title {
				@include textTruncateVertical(3);
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: calc(100% + 15px);
				height: calc(100% + 15px);
				background: linear-gradient(182.59deg, rgba(0, 0, 0, 0.45) 98.44%, rgba(0, 0, 0, 0) 153.62%);
				z-index: -1;
			}

		}

		&.swiper-slide-prev,
		&.swiper-slide-next {
			border: 1px solid $white;
			height: var(--slideHeight) !important;
			transition: height 1.1s linear;

			.main-projects__slide-image {
				opacity: 0;
				transition: opacity 1.1s linear;
			}
		}
	}

	&__slide-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
		z-index: -1;
		transform: scale(1.01);
	}

	&__slide-country {
		display: inline-block;
		font-size: 16px;
		font-weight: 400;
		color: $white;
		margin-bottom: 32px;
		cursor: default;
		user-select: none;
	}

	&__slide-title {
		max-width: 416px;
		font-family: $fontPlayfair;
		font-size: 26px;
		color: $white;
		cursor: default;
		user-select: none;
		@include textTruncateVertical(2);
	}

	&__slide-button {
		position: absolute;
		bottom: 32px;
		font-size: 16px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__control {
		display: flex;
		transform: translateX(120px);
	}

	&__button-prev,
	&__button-next {
		position: relative;
		display: inline-block;
		border: 1px solid $white;
		width: var(--buttonControlSize);
		height: var(--buttonControlSize);
		background:
				linear-gradient($white 0 0) right / var(--filling, 0%) 100% no-repeat,
				linear-gradient($white 0 0) right / var(--filling, 0%) 100% no-repeat;
		z-index: 2;
		transition: background 0.5s ease;

		&:hover {
			--filling: 100%;

			&::before {
				background: url(../img/icons/arrowControlSlideHover.svg) no-repeat transparent center / 36px;
			}
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url(../img/icons/arrowControlSlide.svg) no-repeat transparent center / 36px;
			z-index: 1;
		}
	}

	&__button-next {
		transform: rotate(180deg);
		border-right: 1px solid transparent;
	}

	&__slide-total {
		display: flex;
		align-items: center;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		border-right: 1px solid $white;
		width: calc(var(--buttonControlSize) * 2);
		height: var(--buttonControlSize);
		padding-left: 25px;
		letter-spacing: -0.5px;
	}

	&__slide-total-body {
		flex: 1 1 auto;
		color: $grey2;
	}

	&__slide-current {
		font-size: 36px;
		font-weight: 600;
		color: $white;
	}

	&__slide-all {
		font-size: 16px;
		font-weight: 600;
	}

	&__background-decor {
		transform: translateX(calc(-1 * var(--paddingContainer)));
		width: var(--backgroundDecorWidth);
		height: var(--backgroundDecorHeight);
		border-left: 1px solid $white;
		border-bottom: 1px solid $white;
	}

	@media (max-width: 1680px) {
		--sliderWidth: 100%;

		&__body {
			justify-content: center;
			transform: translateX(0);
		}

		&__background-decor {
			transform: translateX(0);
		}

		&__control {
			margin-left: auto;
			transform: translateX(0);
		}
	}

	@media (max-width: 1366px) {
		--mainProjectsPaddingVertical: 25px;
		--slideWidth: clamp(270px, (453/1366)*100vw, 453px);
		--slideHeight: clamp(193px, (295/1366)*100vw, 295px);
		--slideWidthActive: clamp(345px, (620/1366)*100vw, 620px);
		--slideHeightActive: clamp(234px, (403/1366)*100vw, 403px);

		&__header,
		&__footer {
			padding: 0px var(--paddingContainer);
		}

		.container {
			width: 100%;
			padding: 0;
		}

		&__slide-title {
			line-height: 1.15;
		}
	}

	@media (max-width: 991px) {
		--mainProjectsPaddingVertical: 25px;
		--buttonControlSize: 60px;

		&__background-decor {
			display: none;
		}

		&__body {
			margin-bottom: 40px;
		}

		.swiper {
			overflow: visible;
		}

		&__slide {
			align-self: center;
		}

		&__slide-country {
			margin-bottom: 24px;
			font-size: 14px;
		}

		&__slide-title {
			font-size: clamp(18px, (26/1366)*100vw, 26px);
		}
	}

	@media (max-width: 750px) {
		&__body {
			margin-bottom: 24px;
		}
	}

	@media (max-width: 645px) {
		background-color: $white;

		&__title {
			color: $black;
		}

		&__body {
			padding: 0 var(--paddingContainer) 50px var(--paddingContainer);
			margin-bottom: 0;
		}

		&__background-image {
			display: none;
		}

		&__footer {
			padding-bottom: 75px;
		}

		&__button {
			display: none;

			&--mobile {
				display: block;
				position: absolute;
				bottom: 45px;
				left: var(--paddingContainer);
				font-size: 16px;
			}
		}

		&__control {
			width: 100%;
		}

		&__button-prev,
		&__button-next {
			border: 1px solid $black;
			background:
				linear-gradient($black 0 0) right / var(--filling, 0%) 100% no-repeat,
				linear-gradient($black 0 0) right / var(--filling, 0%) 100% no-repeat;

			&:hover {
				--filling: 100%;

				&::before {
					background: url(../img/icons/arrowControlSlide.svg) no-repeat transparent center / 36px;
				}
			}

			&::before {
				background: url(../img/icons/arrowControlSlideHover.svg) no-repeat transparent center / 36px;
			}
		}

		&__button-prev {
			border-right: 1px solid transparent;
		}

		&__slide-total {
			border: 1px solid $black;
			margin-left: auto;
			padding: 0 auto;
		}

		&__slide-total-body {
			color: $grey2;
		}

		&__slide-current {
			color: $black;
			font-size: 24px;
		}
	}
}
