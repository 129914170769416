.not-found {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: var(--headerHeight);

	&__background {
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		z-index: -1;

		&.left {
			left: 0;
			transform: translateY(clamp(40px, (85/900)*100vw, 85px)) translateX(-50%);
		}

		&.right {
			right: 0;
			transform: translateY(clamp(40px, (85/900)*100vw, 85px)) translateX(50%);
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			bottom: 0;
			object-fit: scale-down;
			object-position: bottom;
		}
	}

	&__background-decor {
		&.top-left {
			position: absolute;
			top: 12%;
			left: clamp(10%, 100vw, 12%);
		}

		&.top-right {
			position: absolute;
			top: 12%;
			right: clamp(10%, 100vw, 12%);
		}

		&.bottom-left {
			position: absolute;
			bottom: 4%;
			left:  clamp(1%, 100vw, 4%);
		}

		&.bottom-right {
			position: absolute;
			bottom: 0;
			right: clamp(2%, 100vw, 7%);
			transform: rotate(90deg);
		}
	}

	&__body {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: default;

		&:after {
			content: '';
			width: 37%;
			height: clamp(125%, (1/749)*100vh, 1%);
			position: absolute;
			top: -14%;
			right: 20%;
			border-bottom: 1px solid $greyBorder;
			border-right: 1px solid $greyBorder;
			z-index: -1;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: -111%;
			left: 43%;
			width: 1px;
			height: 100%;
			background-color: $greyBorder;
			z-index: -1;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		margin-bottom: clamp(32px, (24/1920)*100vw, 24px);
		padding-bottom: 21%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: scale-down;
		}
	}

	&__title {
		font-size: clamp(18px, (48/1024)*100vw, 48px);
		text-align: center;
		margin-bottom: 24px;
		padding: 0px 25px;
	}

	&__button {
		font-size: clamp(16px, (24/1024)*100vw, 24px);
		text-decoration: underline;

		&::after {
			content: none;
		}

		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: 1024px) {
		&__background {

			&.left {
				transform: translateY(clamp(40px, (85/900)*100vw, 85px)) translateX(-60%);
			}

			&.right {
				transform: translateY(clamp(40px, (85/900)*100vw, 85px)) translateX(60%);
			}
		}

		&__image {
			margin-bottom: 48px;
			padding-bottom: 25%;
		}
	}

	@media (max-width: 525px) {
		&__image {
			margin-bottom: 36px;
			padding-bottom: 30%;
		}
	}

	@media (max-height: 669px), (max-width: 1249px) {
		&__body {
			&:before,
			&:after {
				content: none;
			}
		}
	}
}
