.footer-project {

	&__item {
		margin-bottom: 16px;

		&:hover .footer-project__link {
			opacity: 0.65;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: $white;
		transition: opacity 0.15s ease;
		font-size: clamp(14px, (16/1024)*100vw, 16px);
	}
}
