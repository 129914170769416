.main-map {
	--mainMapHeight: clamp(300px, (500/1510)*100vw, 500px);
	width: 100%;
	height: var(--mainMapHeight);
	background-color: $greyDark;

	&__body {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
	}

	&__info {
		padding-top: clamp(24px, (55/1366)*100vw, 55px);
		flex: 0 0 542px;
	}

	&__title {
		color: $white;
		margin-bottom: clamp(24px, (48/1920)*100vw, 48px);
		cursor: default;
	}

	&__description {
		font-size: clamp(14px, (16/1024)*100vw, 16px);
		font-weight: 400;
		color: $white;
		margin-bottom: 24px;
		cursor: default;
	}

	&__country {
		font-family: $fontPlayfair;
		font-size: clamp(16px, (18/1024)*100vw, 18px);
		text-transform: uppercase;
		color: $greyBorder;
		border-left: 1px solid $greyBorder ;
		padding-left: 16px;
		cursor: default;
	}

	&__world {
		flex: 0 1 52%;
		margin-left: auto;
		transform: translateY(
			calc((-10px) + (-75 - (-10)) * ((100vw - 1440px) / (1920 - 1440)))
		);
		width: clamp(400px, (650/1920)*100vw, 650px);
		height: clamp(400px, (650/1920)*100vw, 650px);
		padding: clamp(10px, (30/1510)*100vw, 30px);

	}

	@media (min-width: 1920px) {
		&__world {
			transform: translateY(-75px);
		}
	}

	@media (max-width: 1366px) {
		&__world {
			transform: translateY(-15px);
			padding: 25px;
		}
	}

	@media (max-width: 1099px) {
		--mainMapHeight: 100%;
		&__world {
			padding: 0;
		}
	}

	@media (max-width: 1024px) {
		&__body {
			display: block;
		}

		&__info {
			max-width: 420px;
		}

		&__world {
			margin: 0;
			transform: translateY(0);
			width: 100%;
			height: 100%;
			padding: clamp(10px, (60/1024)*100vw, 60px);
		}
	}
}
