.services {
	width: 100%;

	&__body {
		position: relative;
	}

	&__info {
		max-width: 930px;
		margin-bottom: 64px;
	}

	&__background-decor {
		&.top-right {
			top: var(--backgroundDecorWidth);
			right: 0;
		}
		&.bottom-left {
			bottom: 0;
			left: calc(-1 * var(--paddingContainer) - var(--backgroundDecorWidth));
		}
	}

	&__text {
		margin-bottom: 16px;
	}

	&__emphasis {
		font-weight: 600;
	}

	&__projects {
		margin-bottom: 64px;
	}
}
